<template>
  <v-container class="fill-height" fluid>
    <v-row align="center" justify="center">
      <v-col>
        <v-card max-width="800" class="mx-auto">
          <v-toolbar class="grey darken-2" elevation="1">
            <v-toolbar-title class="white--text">
              Вилучення завантажених оплат
            </v-toolbar-title>
          </v-toolbar>
          <v-card-text class="mt-2">
            <v-form ref="form" v-model="formValid">
              <v-row>
                <v-col cols="12">
                  <Bank multiple label="Банки" :value="banks" @autocompleteChange="bankChange"></Bank>
                </v-col>
                <v-col cols="6">
                  <date-component v-model="date_start" label="Дата початку" :class_="!date_start ? 'req-star' : ''"/>
                </v-col>
                <v-col cols="6">
                  <date-component v-model="date_end" label="Дата закінчення" :class_="!date_end ? 'req-star' : ''"/>
                </v-col>
                <v-col cols="12">
                  <v-btn depressed text block color="secondary darken-1" class="button-accept"
                         :loading="loading"
                         :disabled="!documentReady() && !removing" @click.stop="crud_operation">
                    Виконати
                  </v-btn>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import paymentsAPI from '@/utils/axios/payments'
import {ALERT_SHOW} from "@/store/actions/alert";

export default {
  name: "RemoveDownloadedPayments",
  components: {
    Bank: () => import("@/components/autocomplite/Bank"),
  },
  data() {
    return {
      formValid: false,
      banks: [],
      date_start: null,
      date_end: null,
      removing: false,
      loading: false
    }
  },
  methods: {
    documentReady() {
      if (!this.date_start) {
        return false
      }
      return this.date_end;
    },
    bankChange(payload) {
      this.banks = payload
    },
    crud_operation() {
      if (this.documentReady()) {
        const payload = {
          banks: this.banks.map(item => item.value),
          date_start: this.date_start,
          date_end: this.date_end
        }
        this.removing = true
        this.loading = true
        paymentsAPI.remove_downloaded_payments(payload)
            .then(response => response.data)
            .then(() => {
              this.$store.dispatch(ALERT_SHOW, {text: 'Оплати вилучені успішно', color: 'success'})
              this.$router.push('/')
            })
            .catch(err => {
              const error = err.response.data.detail;
              this.$store.dispatch(ALERT_SHOW, {text: error, color: 'error lighten-1'})
            })
          .finally(() => {
            this.removing = false
            this.loading = false
          })

      } else {
        this.$store.commit(ALERT_SHOW, { text: 'Дата початку та Дата закінчення мають бути заповнені.', color: 'error' })
      }
    }
  },
  created() {
  }
}
</script>

<style scoped>

</style>